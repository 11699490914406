var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.changeProfileData($event)}}},[(_vm.loading)?_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar, list-item-three-line, card-heading"}})],1)],1):_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"2"}},[(!_vm.formData.image && !_vm.image)?_c('img',{staticClass:"cropper",attrs:{"src":require("@/static/icons/avatar.png"),"alt":""}}):(_vm.formData.image && !_vm.image)?_c('img',{staticClass:"cropper",attrs:{"src":_vm.formData.image,"alt":""}}):_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.image,"stencil-size":{
            width: 120,
            height: 120
          },"stencil-props":{
            handlers: {},
            movable: false,
            resizable: false,
            aspectRatio: 1
          },"image-restriction":"stencil"},on:{"change":_vm.change}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('span',{staticClass:"cropper-support-text"},[_c('b',[_vm._v("JPG, PNG, JPEG or WEBp, max. of size 1MB")]),_vm._v(" "),_c('br'),_vm._v(" Recommended size: "),_c('b',[_vm._v("from 120*120px")])])]),_c('v-col',{staticClass:"mt-4",attrs:{"md":"12"}},[_c('primary-button',{staticClass:"upload-btn mr-3",attrs:{"type":"button","normal":""},on:{"click":function($event){return _vm.$refs.file.click()}}},[_c('span',{staticClass:"button"},[_c('input',{ref:"file",staticClass:"d-none",attrs:{"type":"file","accept":"image/jpeg, image/png, image/jpg, image/webp"},on:{"change":function($event){return _vm.loadImage($event)}}}),_vm._v(" Upload New Image ")])]),(!_vm.defaultAvatar || _vm.image)?_c('grey-button',{staticClass:"upload-btn",attrs:{"light-grey":""},on:{"click":_vm.reset}},[_vm._v(" Delete ")]):_vm._e()],1),_c('v-col',{staticClass:"mt-3",attrs:{"md":"12"}},[(_vm.selectedFileStatus === 'type')?_c('span',{staticClass:"cropper-error-msg"},[_vm._v(" Unsupported photo format. You can upload jpg, jpeg, png and webp formats ")]):(_vm.selectedFileStatus === 'size')?_c('span',{staticClass:"cropper-error-msg"},[_vm._v(" Image bigger than 1MB. Try another one ")]):_vm._e(),(_vm.getProfileImgError)?_c('span',{staticClass:"cropper-error-msg"},[_vm._v(" "+_vm._s(_vm.getProfileImgError)+" ")]):_vm._e()])],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('text-field',{attrs:{"label":"Full Name","large":"","outlined":"","error-messages":errors},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}])})],1),_c('v-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('text-field',{attrs:{"label":"Email","type":"email","large":"","outlined":"","error-messages":errors},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}])})],1),_c('v-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"timezone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('autocomplete',{staticClass:"custom-autocomplete",attrs:{"label":"Timezones","items":_vm.getUserProfile.timezones,"item-text":"value","item-value":"key","deletable-chips":false,"small-chips":false,"error-messages":errors},model:{value:(_vm.formData.timezone),callback:function ($$v) {_vm.$set(_vm.formData, "timezone", $$v)},expression:"formData.timezone"}})]}}])})],1),_c('v-col',{attrs:{"md":"12"}},[_c('input-label',[_vm._v(" Pricing Plan ")]),_c('v-col',{staticClass:"d-flex align-center pa-0"},[_c('grey-button',{attrs:{"small":""}},[_vm._v(" FREE ")]),_c('div',{staticClass:"ml-2"},[_c('span',{staticClass:"text-primary"},[_vm._v(" Upgrade ")]),_vm._v(" pricing plan ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('info',{staticClass:"ml-2"})],1)]}}])},[_c('span',[_vm._v("Few words about benefits of pro account")])])],1)],1),_c('v-col',[_c('primary-button',{staticClass:"mt-5",attrs:{"loading":_vm.loading,"type":"submit"}},[_vm._v(" Save Changes ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }