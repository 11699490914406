<template>
  <validation-observer ref="observer">
    <v-form ref="form" @submit.prevent="changeProfileData">
      <v-row v-if="loading">
        <v-col md="4">
          <v-skeleton-loader
            type="list-item-avatar, list-item-three-line, card-heading"
          />
        </v-col>
      </v-row>
      <v-row v-else no-gutters>
        <v-col md="2">
          <img
            v-if="!formData.image && !image"
            class="cropper"
            src="@/static/icons/avatar.png"
            alt=""
          >
          <img
            v-else-if="formData.image && !image"
            class="cropper"
            :src="formData.image"
            alt=""
          >
          <cropper
            v-else
            ref="cropper"
            class="cropper"
            :src="image"
            :stencil-size="{
              width: 120,
              height: 120
            }"
            :stencil-props="{
              handlers: {},
              movable: false,
              resizable: false,
              aspectRatio: 1
            }"
            image-restriction="stencil"
            @change="change"
          />
        </v-col>
        <v-col md="4">
          <v-row no-gutters>
            <v-col md="12">
              <span class="cropper-support-text">
                <b>JPG, PNG, JPEG or WEBp, max. of size 1MB</b> <br>
                Recommended size: <b>from 120*120px</b>
              </span>
            </v-col>
            <v-col md="12" class="mt-4">
              <primary-button
                type="button"
                normal
                class="upload-btn mr-3"
                @click="$refs.file.click()"
              >
                <span class="button">
                  <input
                    ref="file"
                    class="d-none"
                    type="file"
                    accept="image/jpeg, image/png, image/jpg, image/webp"
                    @change="loadImage($event)"
                  >
                  Upload New Image
                </span>
              </primary-button>
              <grey-button
                v-if="!defaultAvatar || image"
                light-grey
                class="upload-btn"
                @click="reset"
              >
                Delete
              </grey-button>
            </v-col>
            <v-col md="12" class="mt-3">
              <span
                v-if="selectedFileStatus === 'type'"
                class="cropper-error-msg"
              >
                Unsupported photo format. You can upload jpg, jpeg, png and webp formats
              </span>
              <span
                v-else-if="selectedFileStatus === 'size'"
                class="cropper-error-msg"
              >
                Image bigger than 1MB. Try another one
              </span>
              <span
                v-if="getProfileImgError"
                class="cropper-error-msg"
              >
                {{ getProfileImgError }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="12">
          <validation-provider
            v-slot="{ errors }"
            name="name"
            rules="required|max:150"
          >
            <text-field
              v-model="formData.name"
              label="Full Name"
              large
              outlined
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col md="12">
          <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="required|email|max:255"
          >
            <text-field
              v-model="formData.email"
              label="Email"
              type="email"
              large
              outlined
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col md="12">
          <validation-provider
            v-slot="{ errors }"
            name="timezone"
            rules="required"
          >
            <autocomplete
              v-model="formData.timezone"
              label="Timezones"
              :items="getUserProfile.timezones"
              item-text="value"
              item-value="key"
              :deletable-chips="false"
              :small-chips="false"
              class="custom-autocomplete"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col md="12">
          <input-label>
            Pricing Plan
          </input-label>
          <v-col class="d-flex align-center pa-0">
            <grey-button small>
              FREE
            </grey-button>
            <div class="ml-2">
              <span class="text-primary">
                Upgrade
              </span> pricing plan
            </div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="d-flex align-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  <info
                    class="ml-2"
                  />
                </div>
              </template>
              <span>Few words about benefits of pro account</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col>
          <primary-button
            :loading="loading"
            class="mt-5"
            type="submit"
          >
            Save Changes
          </primary-button>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex';
import TextField from '@/components/ui/TextField/TextField';
import PrimaryButton from '@/components/ui/Buttons/PrimaryButton';
import GreyButton from '@/components/ui/Buttons/GreyButton';
import Info from '@/components/ui/Icons/Info';
import InputLabel from '@/components/ui/InputLabel/InputLabel';
import Autocomplete from '@/components/ui/Autocomplete/Autocomplete';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: 'Profile',
  components: {
    GreyButton,
    PrimaryButton,
    TextField,
    Info,
    InputLabel,
    Autocomplete,
    Cropper,
  },
  data() {
    return {
      formData: {
        name: '',
        image: '',
        email: '',
        timezone: '',
      },
      image: null,
      selectedFileStatus: 'success',
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getProfileImgError: 'getProfileImgError',
      getUserProfile: 'getUserProfile',
    }),
    defaultAvatar() {
      return this.formData.image.includes('default.png');
    },
  },
  async mounted() {
    this.loading = true;
    this.$store.commit('updateProfileImgError', null);
    await this.$store.dispatch('GET_USER_PROFILE')
      .then(() => {
        Object.keys(this.formData).forEach((key) => {
          switch (key) {
            case 'name':
            case 'email':
            case 'image':
              this.formData[key] = this.getUserProfile[key];
              break;
            case 'timezone':
              this.formData[key] = this.getUserProfile.timezones.find((el) => el.selected).key;
              break;
            default:
              break;
          }
        });
      }).finally(() => {
        this.loading = false;
      });
  },
  methods: {
    reset() {
      this.formData.image = '';
      this.image = '';
      this.$refs.file.value = null;
      this.$store.dispatch('RESET_USER_PHOTO');
    },

    change(val) {
      return val;
    },

    loadImage(event) {
      const input = event.target;
      const acceptedImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

      if (input.files && input.files[0]) {
        if (input.files[0].size >= 1000000) {
          this.selectedFileStatus = 'size';

          return;
        }

        if (!acceptedImageTypes.includes(input.files[0].type)) {
          this.selectedFileStatus = 'type';

          return;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
          this.image = e.target.result;
        };

        reader.readAsDataURL(input.files[0]);
        this.selectedFileStatus = 'success';
      }
    },
    changeProfileData() {
      const observerRef = this.$refs.observer;
      observerRef.validate().then((isValid) => {
        if (isValid) {
          this.loading = true;

          if (this.$refs.cropper) {
            const { canvas } = this.$refs.cropper.getResult();
            canvas.toBlob((blob) => {
              const data = new FormData();
              data.append('image', blob);
              data.append('name', this.formData.name);
              data.append('email', this.formData.email);
              data.append('timezone', this.formData.timezone);
              this.$store.dispatch('UPDATE_USER_PROFILE', data)
                .then(() => {
                  this.selectedFileStatus = 'success';
                  this.loading = false;
                  observerRef.reset();
                });
            });
          } else {
            const data = new FormData();
            data.append('name', this.formData.name);
            data.append('email', this.formData.email);
            data.append('timezone', this.formData.timezone);
            this.$store.dispatch('UPDATE_USER_PROFILE', data)
              .then(() => {
                this.loading = false;
                observerRef.reset();
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/clockyme/buttons.scss";

  .cropper {
    border-radius: 45px;
    width: 120px;
    min-height: 120px;
    max-height: 120px;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .cropper-support-text {
    font-size: $type-xs;
    line-height: $type-xs-lh;
    display: block;
  }
  .upload-btn {
    font-size: $type-s !important;
  }
  .cropper-error-msg {
    display: block;
    font-size: $type-xs;
    color: $error;
  }
  ::v-deep .custom-autocomplete {
    max-width: 400px;

    .v-select__slot {
      input {
        padding-top: 2px;
        padding-bottom: 0;
        cursor: pointer;
      }
    }

    .v-input__slot {
      min-height: 40px;
    }
  }
</style>
