<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8"
      cy="8"
      r="8"
      fill="#656375"
    />
    <path
      d="M8.85717 11.1429C8.85717 11.6162 8.47342
    12 8.00003 12C7.52664 12 7.14289 11.6162 7.14289
    11.1429C7.14289 10.6695 7.52664 10.2857 8.00003
    10.2857C8.47342 10.2857 8.85717 10.6695 8.85717 11.1429Z"
      fill="#ECEBF0"
    />
    <path
      d="M5.78571 5.71429C5.78571 5.99043
    6.00956 6.21429 6.28571 6.21429C6.56185
    6.21429 6.78571 5.99043 6.78571 5.71429H5.78571ZM7.49999 9C7.49999
    9.27614 7.72385 9.5 7.99999 9.5C8.27613 9.5 8.49999 9.27614 8.49999
    9H7.49999ZM6.78571 5.71429V5.68523H5.78571V5.71429H6.78571ZM9.21428
    5.74334V5.86111H10.2143V5.74334H9.21428ZM8.60818 6.99362L8.40836 7.12683L8.96306
    7.95888L9.16288 7.82567L8.60818 6.99362ZM7.49999 8.82413V9H8.49999V8.82413H7.49999ZM8.40836
    7.12683C7.84086 7.50516 7.49999 8.14208 7.49999 8.82413H8.49999C8.49999
    8.47643 8.67376 8.15175 8.96306 7.95888L8.40836 7.12683ZM9.21428 5.86111C9.21428 6.3162
    8.98683 6.74118 8.60818 6.99362L9.16288 7.82567C9.81973 7.38776 10.2143 6.65055
    10.2143 5.86111H9.21428ZM7.97094 4.5C8.65761 4.5 9.21428 5.05666 9.21428
    5.74334H10.2143C10.2143 4.50438 9.2099 3.5 7.97094 3.5V4.5ZM6.78571 5.68523C6.78571 5.03065
    7.31635 4.5 7.97094 4.5V3.5C6.76407 3.5 5.78571 4.47836 5.78571 5.68523H6.78571Z"
      fill="#ECEBF0"
    />
  </svg>
</template>

<script>
export default {
  name: 'Info',
};
</script>

<style scoped>

</style>
