<template>
  <v-btn
    depressed
    outlined
    class="clockyme-button clockyme-button__grey"
    :class="[
      {'small-size': small},
      {'light-grey': lightGrey},
    ]"
    :ripple="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'GreyButton',
  props: {
    small: Boolean,
    lightGrey: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/clockyme/buttons.scss";
  .small-size {
    height: 28px !important;
    font-size: $type-xs !important;
  }
  .light-grey {
    background-color: #EEEDF4 !important;
    color: $grey !important;
  }
</style>
